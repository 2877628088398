import { ChakraProvider } from "@chakra-ui/react";
import { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import { Layout } from "../components/Layout";
import theme from "../theme";
import { v4 as uuidv4 } from "uuid";
import nookies from "nookies";
import { useEffect } from "react";

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const voltwiseIdCookie = nookies.get(null).voltwise_id;

    if (!voltwiseIdCookie) {
      nookies.set(null, "voltwise_id", uuidv4(), {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
        sameSite: "Lax",
        secure: process.env.NODE_ENV === "production",
      });
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Script
        id="google-analytics"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script
        id="gta-script"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
            `,
        }}
      />
      <Head>
        <meta
          name="ahrefs-site-verification"
          content="9d9271af98cd433624dddf54b3f859642a4c65040ff0b276dd174df26db8923f"
        />

        <title>Voltwise | Find Solar & Heat Pumps Installers</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="title" content="Find solar panel installers near you" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta
          property="og:title"
          content="Voltwise | Find Solar & Heat Pumps Installers"
        />
        <meta property="og:site_name" content="Voltwise" />
        <meta
          property="og:description"
          content="Enter your postcode to see well-reviewed installers"
        />
        <meta
          name="description"
          content="Use Voltwise to find local, well-reviewed solar panel, heat pump, and EV charger installers. Search your postcode, read reviews, and get quotes. The simplest way to find an installer who can help you cut both your energy bills and your carbon footprint."
        />
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </ChakraProvider>
  );
}

export default MyApp;
