import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  sm: "40em",
  md: "52em",
  lg: "64em",
  xl: "80em",
};

const theme = extendTheme({
  styles: {
    global: {
      p: {
        marginBottom: "1rem",
        marginTop: "1rem",
      },
      a: {
        color: "teal.500",
        fontWeight: "bold",
      },
      ul: {
        li: { marginBottom: "10px", marginLeft: "10px" },
        listStylePosition: "outside",
        marginBottom: "1rem",
      },
      ol: {
        li: { marginBottom: "10px", marginLeft: "10px" },
        listStylePosition: "outside",
      },
      blockquote: {
        borderLeft: "4px solid #ddd",
        color: "#666",
        fontStyle: "italic",
        marginLeft: 0,
        marginRight: 0,
        marginTop: "2rem",
        marginBottom: "2rem",
        paddingLeft: "1rem",
      },
      h3: {
        fontSize: "1.1rem",
        fontWeight: "bold",
        marginTop: "2rem",
        marginBottom: "1rem",
        color: "#0B3948",
      },
      h2: {
        color: "#0B3948",
        fontSize: "1.5rem",
        fontWeight: "bold",
        marginTop: "2rem",
        marginBottom: "1rem",
      },
      h1: {
        color: "#0B3948",
        fontSize: "3rem",
        fontWeight: "bold",
        marginTop: "2rem",
        marginBottom: "1rem",
      },
    },
  },
  semanticTokens: {
    colors: {
      text: {
        default: "#16161D",
        _dark: "#ade3b8",
      },
      heroGradientStart: {
        default: "#7928CA",
        _dark: "#e3a7f9",
      },
      heroGradientEnd: {
        default: "#FF0080",
        _dark: "#fbec8f",
      },
    },
    radii: {
      button: "12px",
    },
  },
  colors: {
    black: "#16161D",
  },
  // fonts,
  breakpoints,
});

export default theme;
