import { Box } from "@chakra-ui/react";
import Header from "./Header";
import { Wrapper, WrapperVariant } from "./Wrapper";

interface LayoutProps {
  variant?: WrapperVariant;
  children: object;
}

export const Layout: React.FC<LayoutProps> = ({ children, variant }) => {
  return (
    <>
      <Box bg={"#fcfdff"} m={"0"}>
        <Header />
        <br />
        <Wrapper variant={variant}>{children}</Wrapper>
      </Box>
    </>
  );
};
