import { Box } from "@chakra-ui/react";
import Footer from "./Footer";

export type WrapperVariant = "small" | "regular";

interface WrapperProps {
  variant?: WrapperVariant;
  children: any;
}

export const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  return (
    <Box w={"100%"} mx="auto" mt={"1%"}>
      <Box minHeight={"100vh"}>{children}</Box>
      <Footer />
    </Box>
  );
};
