import { Box, Text } from "@chakra-ui/react";
import Link from "next/link";

export default function Footer() {
  return (
    <Box color={"gray.200"} textAlign="center" pb={4} pt={16}>
      <Text m={0}>
        © 2022 Voltwise Platform. All rights reserved.{" "}
        <Link href={`/all-installers`} legacyBehavior>
          <a
            style={{
              color: "#E2E8F0",
              fontWeight: "normal",
            }}
          >
            View all installers.
          </a>
        </Link>
      </Text>
    </Box>
  );
}
